<template>
  <app-form-view
    app="audit_trial_balance"
    model="trialbalanceheader"
    api-url="trial-balance/trial-balance-header/"
    :title="$t('menu.trialBalance')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    :prepare-data="prepareData"
    v-model="formData"
    :isValid="isValid"
    :validateMessage="formData.validateMessage"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Company"
            type="select-server"
            :label="$t('fields.companyName')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/'
            }"
            v-model="formData.contact_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveContact"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="period"
            type="select-server"
            :label="$t('fields.period')"
            :view="view"
            :binds="{
              apiUrl: 'chart-of-account/period/'
            }"
            v-model="formData.period"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_period"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="chartOfAccount"
            type="select-server"
            :label="$t('fields.chartOfAccount')"
            :view="view"
            :binds="{
              apiUrl: 'chart-of-account/chart-of-account-header/'
            }"
            v-model="formData.chartOfAccountHeader_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveCOA"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_COA"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="startDate"
            type="datepicker"
            :label="$t('fields.startDate')"
            :view="view"
            v-model="formData.startDate"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveStartDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_startDate"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="endDate"
            type="datepicker"
            :label="$t('fields.endDate')"
            :view="view"
            v-model="formData.endDate"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveEndDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_endDate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>

      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>Chart of Account Details</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="table"
                  app="audit_trial_balance"
                  model="trialbalanceheader"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="headers"
                  :serverItems="trialBalanceItems"
                  :clientItems="trialBalanceItems"
                  :loading="loading"
                  :server-items-length="trialBalanceItemsLength"
                  :options="tableOptions"
                  hide-default-footer
                  v-model="trialBalanceSelected"
                  @server="getTrialBalanceItems"
                  @client="getTrialBalanceClientItems"
                  @delete="onDeleteItem"
                  @edit="onEdit"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title> Trial Balance </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogTrialBalance" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <slot name="actions">
                            <v-btn
                              v-show="mode === 'edit'"
                              dark
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              color="primary"
                              dark
                              v-show="mode === 'edit'"
                              @click="onImportTrialBalanceDetail()"
                            >
                              {{ $t('btn.import') }}
                            </v-btn>
                            <v-btn
                              v-show="
                                trialBalanceSelected.length && mode === 'edit'
                              "
                              color="error"
                              @click="onDelete(trialBalanceSelected)"
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                          </slot>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.chartOfAccountDetail_id"
                                    name="chartOfAccount"
                                    type="select-server"
                                    :label="$t('fields.chartOfAccount')"
                                    :view="view"
                                    :binds="{
                                      apiUrl:
                                        'chart-of-account/chart-of-account-detail/'
                                    }"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.beginning_balance"
                                    name="beginning_balance"
                                    :label="$t('fields.beginningBalance')"
                                    type="number"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.debit"
                                    name="debit"
                                    :label="$t('fields.debit')"
                                    type="number"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.credit"
                                    name="credit"
                                    :label="$t('fields.credit')"
                                    type="number"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.ending_balance"
                                    name="ending_balance"
                                    :label="$t('fields.endingBalance')"
                                    type="number"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.note"
                                    name="note"
                                    :label="$t('fields.note')"
                                    :view="view"
                                  ></app-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeTrialBalance"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveTrialBalance"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="dialogTrialBalanceDetailImport"
                        max-width="500px"
                      >
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="10">
                                  <app-xlsx-import
                                    @change="
                                      onTrialBalanceDetailImportFileChange
                                    "
                                  />
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="10">
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    :href="trialBalanceDetailTemplateUrl"
                                  >
                                    Download Template
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="10">
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="onShowDataDict()"
                                  >
                                    COA Data
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  {{ tempTrialBalanceDetailImportStatus }}
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="onCloseImportTrialBalanceDetail"
                            >
                              {{ $t('btn.cancel') }}
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="onImportTrialBalanceDetailDo"
                            >
                              {{ $t('btn.import') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDataDict" max-width="900px">
                        <v-card flat>
                          <v-card-text>
                            <app-table
                              server-side
                              ref="table"
                              app="chart_of_account"
                              model="chartofaccountheader"
                              hide-edit
                              hide-delete
                              :headers="chartOfAccountHeaders"
                              :serverItems="chartOfAccountItems"
                              :clientItems="chartOfAccountItems"
                              :loading="loading"
                              :server-items-length="chartOfAccountItemsLength"
                              v-model="chartOfAccountSelected"
                              @server="getChartOfAccountItems"
                              :isSelecteable="false"
                            ></app-table>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-subtitle-1">Totals</td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumBeginningBalance) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumDebit) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumCredit) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumEndingBalance) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumCalEndingBalance) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumDiffEndingBalance) }}
                      </td>
                    </tr>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable.vue'
import _ from 'lodash'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import readXlsxFile from 'read-excel-file'
import Papa from 'papaparse'
import Decimal from 'decimal.js'

export default {
  name: 'trial-balance-form',
  components: {
    AppXlsxImport,
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      tab: null,
      loading: false,
      formData: {
        state: 'draft'
      },
      tableOptions: {
        itemsPerPage: 0, // Set the desired number of items per page
        page: 1
      },
      approveItems: [
        'approve_name',
        'approve_contact',
        'approve_period',
        'approve_COA',
        'approve_startDate',
        'approve_endDate'
      ],
      trialBalanceItems: [],
      trialBalanceClientItems: [],
      trialBalanceSelected: [],
      trialBalanceItemsLength: 0,
      dialogTrialBalance: false,
      editedItem: {
        chartOfAccountDetail_id: null,
        beginning_balance: null,
        debit: null,
        credit: null,
        ending_balance: null,
        calculated_ending_balance: null,
        calculated_diff_ending_balance: null,
        note: null
      },
      apiMethod: 'post',
      dialogTrialBalanceDetailImport: false,
      dialogDataDict: false,
      trialBalanceDetailImportForm: {},
      tempTrialBalanceDetailImports: [],
      chartOfAccountItems: [],
      chartOfAccountClientItems: [],
      chartOfAccountSelected: [],
      chartOfAccountItemsLength: 0
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isValid() {
      var errorMessage = ''
      var isTBValid = true
      if (this.trialBalanceItemsLength > 0) {
        if (Math.abs(this.sumBeginningBalance) > Number.EPSILON) {
          isTBValid = false
          errorMessage = this.$t('alert.validationBeginningBalance')
        }

        if (Math.abs(this.sumDebit - this.sumCredit) > Number.EPSILON) {
          isTBValid = false
          errorMessage = errorMessage + this.$t('alert.validationCreditDebit')
        }

        if (Math.abs(this.sumEndingBalance) > Number.EPSILON) {
          isTBValid = false
          errorMessage = errorMessage + this.$t('alert.validationEndingBalance')
        }

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.formData.validateMessage = errorMessage
      }
      return isTBValid
    },
    sumBeginningBalance() {
      return this.sumField('beginning_balance')
    },
    sumDebit() {
      return this.sumField('debit')
    },
    sumCredit() {
      return this.sumField('credit')
    },
    sumEndingBalance() {
      return this.sumField('ending_balance')
    },
    sumCalEndingBalance() {
      return this.sumField('calculated_ending_balance')
    },
    sumDiffEndingBalance() {
      return this.sumField('calculated_diff_ending_balance')
    },

    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    chartOfAccountHeaders() {
      return [
        {
          text: this.$t('fields.chart_of_account_id'),
          value: 'id',
          hideFilter: true
        },
        {
          text: this.$t('fields.accountCode'),
          value: 'code',
          hideFilter: true
        },
        {
          text: this.$t('fields.accountName'),
          value: 'name',
          hideFilter: true
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'customAccountType',
          hideFilter: true
        },
        {
          text: this.$t('fields.standardAccountCode'),
          value: 'masterChartOfAccountDetail_id.code',
          hideFilter: true
        },
        {
          text: this.$t('fields.standardAccountName'),
          value: 'masterChartOfAccountDetail_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.customerAccountName'),
          value: 'customAccountName',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        }
      ]
    },
    headers() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chartOfAccountDetail_id.code',
          hideFilter: true
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chartOfAccountDetail_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'chartOfAccountDetail_id.customAccountType',
          hideFilter: true
        },
        {
          text: this.$t('fields.beginningBalance'),
          value: 'beginning_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.debit'),
          value: 'debit',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.credit'),
          value: 'credit',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.endingBalance'),
          value: 'ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.calculatedEndingBalance'),
          value: 'calculated_ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.different'),
          value: 'calculated_diff_ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    trialBalanceDetailTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/Trial%20Balance%20Template.xlsx`
    },
    tempTrialBalanceDetailImportStatus() {
      if (
        !this.tempTrialBalanceDetailImports ||
        !this.tempTrialBalanceDetailImports.length
      ) {
        return null
      }
      return `${this.tempTrialBalanceDetailImports.length} records will be imported`
    }
  },
  created() {
    if (this.$route.name === 'trialBalanceCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'trialBalanceCreate'
      })
    }
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'trialBalanceEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'trialBalanceEdit',
          text: value ?? 'N/A',
          to: {
            name: 'trialBalanceEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    getTrialBalanceItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = (Array.isArray(options.sortBy) ? options.sortBy : [])
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${
                options.sortDesc && options.sortDesc[index] ? '-' : ''
              }${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (!options.sortBy || options.sortBy.length === 0) {
          ordering = 'chartOfAccountDetail_id__code'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          trialBalanceHeader_id: this.$route.params.id
        }
      } else {
        this.params = {
          trialBalanceHeader_id: this.$route.params.id,
          ordering: 'chartOfAccountDetail_id__code'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'trial-balance/trial-balance-detail/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.trialBalanceItemsLength = data.count
          this.trialBalanceItems = data.results
          this.trialBalanceClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTrialBalanceClientItems(multiSearch) {
      this.trialBalanceClientItems = this.trialBalanceItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    getChartOfAccountItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        const ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          chartOfAccountHeader_id: this.formData.chartOfAccountHeader_id.id
        }
      } else {
        this.params = {
          chartOfAccountHeader_id: this.formData.chartOfAccountHeader_id.id
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'chart-of-account/chart-of-account-detail/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.chartOfAccountItemsLength = data.count
          this.chartOfAccountItems = data.results
          this.chartOfAccountClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    accountFieldFormat(value) {
      if (value < 0) {
        const formatted = Math.abs(value)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return `(${formatted})`
      } else {
        return `${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
      }
    },
    sumField(key) {
      return this.trialBalanceItems.reduce(
        (a, b) => a.plus(new Decimal(parseFloat(b[key] || 0))),
        new Decimal(0)
      )
    },
    closeTrialBalance() {
      this.editedItem = {}
      this.dialogTrialBalance = false
    },
    saveTrialBalance() {
      this.loading = true
      this.editedItem.calculated_ending_balance = parseFloat(
        parseFloat(this.editedItem.beginning_balance) +
          parseFloat(this.editedItem.debit) -
          parseFloat(this.editedItem.credit)
      ).toFixed(2)

      this.editedItem.calculated_diff_ending_balance = parseFloat(
        parseFloat(this.editedItem.ending_balance) -
          (parseFloat(this.editedItem.beginning_balance) +
            parseFloat(this.editedItem.debit) -
            parseFloat(this.editedItem.credit))
      ).toFixed(2)

      this.editedItem.trialBalanceHeader_id = this.$route.params.id

      this.editedItem.chartOfAccountDetail_id =
        this.editedItem.chartOfAccountDetail_id.id

      const data = this.editedItem

      this.$api({
        method: this.apiMethod,
        url: `trial-balance/trial-balance-detail/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeTrialBalance()
      this.getTrialBalanceItems()
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTrialBalance = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `trial-balance/trial-balance-detail/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    prepareData(data) {
      data.period = data.period.id
      data.contact_id = data.contact_id.id
      data.chartOfAccountHeader_id = data.chartOfAccountHeader_id.id
      // data.customer_company_id = 0 // need to edit later to send current customer company id
      return data
    },
    onImportTrialBalanceDetail() {
      this.TrialBalanceDetailImportForm = {}
      this.dialogTrialBalanceDetailImport = true
    },
    onShowDataDict() {
      this.dialogDataDict = true
    },
    onCloseImportTrialBalanceDetail() {
      this.trialBalanceDetailImportForm = {}
      this.dialogTrialBalanceDetailImport = false
    },
    onTrialBalanceDetailImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempTrialBalanceDetailImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 8) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 8. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempTrialBalanceDetailImports.push({
                  chartOfAccountDetail_id: row[0],
                  beginning_balance: row[1]?.trim().replace(/[,-]/g, ''),
                  debit: row[2]?.trim().replace(/[,-]/g, ''),
                  credit: row[3]?.trim().replace(/[,-]/g, ''),
                  ending_balance: row[4]?.trim().replace(/[,-]/g, ''),
                  calculated_ending_balance: row[5]
                    ?.trim()
                    .replace(/[,-]/g, ''),
                  calculated_diff_ending_balance: row[6]
                    ?.trim()
                    .replace(/[,-]/g, ''),
                  note: row[7],
                  trialBalanceHeader_id: this.$route.params.id
                })
              }
            }
          })
        } else {
          this.tempTrialBalanceDetailImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 8) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 8. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempTrialBalanceDetailImports.push({
                  chartOfAccountDetail_id: row[0],
                  beginning_balance: row[1],
                  debit: row[2],
                  credit: row[3],
                  ending_balance: row[4],
                  calculated_ending_balance: row[5],
                  calculated_diff_ending_balance: row[6],
                  note: row[7],
                  trialBalanceHeader_id: this.$route.params.id
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportTrialBalanceDetailDo() {
      try {
        this.loading = true
        const data = this.tempTrialBalanceDetailImports
        await this.$api({
          method: 'post',
          url: `trial-balance/trial-balance-detail/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportTrialBalanceDetail()
        await this.getTrialBalanceItems()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
